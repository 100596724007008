import React from 'react';
import * as PropTypes from 'prop-types';
import seedrandom from 'seedrandom';
import { isMobile } from 'react-device-detect';
import Cell from 'components/Cell';
import FilmTakeover from 'components/FilmTakeover';
import { css } from 'utilities';

import styles from './grid.module.scss';

const CELL_SIZES = {
  mobile: { w: 240, h: 135 },
  desktop: { w: 320, h: 180 },
};

class Grid extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      cellClicked: null,
      cellSize: isMobile ? CELL_SIZES.mobile : CELL_SIZES.desktop,
      gridPosition: { x: 0, y: 0 },
      dragging: false,
      takeoverOpen: false,
      cells: [],
    };
    this.seed = Math.random();
  }

  componentDidMount() {
    this.addEventListeners();
    this.configureGrid();
  }

  addEventListeners = () => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', this.resize);
    }

    if (isMobile) {
      this.gridRef.addEventListener('touchstart', this.dragStart);
      this.gridRef.addEventListener('touchend', this.dragStop);
      this.gridRef.addEventListener('touchmove', this.drag);
    } else {
      this.gridRef.addEventListener('mousedown', this.dragStart);
      this.gridRef.addEventListener('mouseup', this.dragStop);
      this.gridRef.addEventListener('mousemove', this.drag);
    }
  };

  getWindow = () => {
    if (typeof window !== 'undefined') {
      return window;
    }
    return { innerWidth: 1000, innerHeight: 1000 };
  };

  resize = () => {
    this.configureGrid();
  };

  dragStart = e => {
    const { dragging, takeoverOpen } = this.state;
    const event = isMobile ? e.touches[0] : e;
    if (!dragging && !takeoverOpen) {
      this.lastDragPosition = { x: event.clientX, y: event.clientY };
      this.setState({ dragging: true });
      this.draggingStarted = false;
    }
  };

  drag = e => {
    const { dragging, gridPosition } = this.state;
    const event = isMobile ? e.touches[0] : e;
    if (dragging) {
      this.draggingStarted = true;
      const newGridPosition = {
        x: gridPosition.x + (event.clientX - this.lastDragPosition.x),
        y: gridPosition.y + (event.clientY - this.lastDragPosition.y),
      };
      this.setState({ dragging: true, gridPosition: newGridPosition });
      this.configureGrid();
      this.lastDragPosition = { x: event.clientX, y: event.clientY };
    }
  };

  dragStop = e => {
    const { dragging } = this.state;
    const event = isMobile ? e.touches[0] : e;
    if (dragging) {
      this.setState({ dragging: false });
      if (!this.draggingStarted) {
        this.handleCellClicked(event);
      }
      this.draggingStarted = false;
    }
  };

  handleCellClicked = e => {
    console.log(this.cellClicked);
    if (this.cellClicked.special !== true) {
      this.setState({ cellClicked: this.cellClicked, takeoverOpen: true });
    }
  };

  setCellClicked = cell => {
    this.cellClicked = cell;
  };

  closeTakeover = () => {
    this.setState({ takeoverOpen: false });
  };

  configureGrid = () => {
    const { films } = this.props;
    const { cellSize, gridPosition } = this.state;

    const newCells = [];

    const windowWidth = this.getWindow().innerWidth;
    const windowHeight = this.getWindow().innerHeight;

    const cellsX = Math.ceil(windowWidth / cellSize.w + 8);
    const cellsY = Math.ceil(windowHeight / cellSize.h + 8);

    const halfX = Math.floor(cellsX * 0.5);
    const halfY = Math.floor(cellsY * 0.5);

    const modX = Math.ceil(gridPosition.x / cellSize.w);
    const modY = Math.ceil(gridPosition.y / cellSize.h);

    for (let y = -halfY - modY; y < cellsY - halfY - modY; y += 1) {
      for (let x = -halfX - modX; x < cellsX - halfX - modX; x += 1) {
        const randomX = seedrandom(x + this.seed);
        const filmIndex = Math.abs(Math.round((randomX() * 10 + y) % (films.length - 1)));
        const newCell = {
          x,
          y,
          film: films[filmIndex],
        };

        newCells.push(newCell);
      }
    }

    this.setState({ cells: newCells });
  };

  render() {
    const { ready, title } = this.props;
    const { cells, cellSize, gridPosition, dragging, cellClicked, takeoverOpen } = this.state;

    const windowWidth = this.getWindow().innerWidth;
    const windowHeight = this.getWindow().innerHeight;

    const cellElements = cells.map(cell => {
      const special =
        cell.x % Math.ceil(windowWidth / cellSize.w + 1) === 0 &&
        cell.y % Math.ceil(windowHeight / cellSize.h + 1) === 0;

      const rawX = cellSize.w * cell.x + gridPosition.x;
      const rawY = cellSize.h * cell.y + gridPosition.y;
      const x = rawX + windowWidth * 0.5 - cellSize.w * 0.5;
      const y = rawY + windowHeight * 0.5 - cellSize.h * 0.5;

      const opacity =
        1 -
        (Math.abs(Math.abs(rawX) / (windowWidth * 0.5)) +
          Math.abs(Math.abs(rawY) / (windowHeight * 0.5))) *
          0.5;

      const blur = (1 - opacity) * 2;

      return (
        <Cell
          key={`${cell.x}-${cell.y}`}
          dragging={dragging}
          cell={cell}
          special={special}
          onclick={this.setCellClicked}
          title={title}
          style={{
            transform: `translate3d(${x}px, ${y}px, 0)`,
            filter: `blur(${blur}px)`,
          }}
        />
      );
    });

    const takeover = cellClicked ? (
      <FilmTakeover
        film={cellClicked.film}
        open={takeoverOpen}
        closeTakeover={this.closeTakeover}
      />
    ) : (
      ''
    );

    return (
      <>
        <div
          className={css(styles.content, ready && styles.ready, dragging && styles.dragging)}
          ref={r => {
            this.gridRef = r;
          }}
        >
          {cellElements}
        </div>
        {takeover}
      </>
    );
  }
}

Grid.propTypes = {
  ready: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  films: PropTypes.array.isRequired,
};

export default Grid;
