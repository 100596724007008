import React from 'react';
import * as PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { isMobile } from 'react-device-detect';
import { Link } from 'gatsby';
import { css } from 'utilities';

import styles from './cell.module.scss';

class Cell extends React.PureComponent {
  componentDidMount() {
    const { onclick, cell, special } = this.props;
    this.cellRef.addEventListener('mousedown', () => {
      if (special) {
        onclick({ special: true });
      } else {
        onclick(cell);
      }
    });
  }

  render() {
    const { style, dragging, cell, special, title } = this.props;
    let content;

    if (special) {
      content = (
        <div
          className={css(styles.special, dragging && styles.dragging)}
          ref={r => {
            this.cellRef = r;
          }}
        >
          <div className={styles.specialContent}>
            <h1 className={styles.title}>{title}</h1>
            <ul className={styles.linkList}>
              <li className={styles.linkItem}>
                <Link className={styles.specialLink} to="about">
                  About
                </Link>
              </li>
              <li className={styles.linkItem}>
                <a
                  className={styles.specialLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://vimeo.com/360353876"
                >
                  Reel
                </a>
              </li>
              <li className={styles.linkItem}>
                <a className={styles.specialLink} href="mailto:matan@matanhatsav.com">
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      );
    } else {
      content = (
        <div
          className={css(styles.content, dragging && styles.dragging)}
          ref={r => {
            this.cellRef = r;
          }}
        >
          <a className={css(styles.link)} href={`#${Math.abs(cell.x + cell.y)}`}>
            {cell.film ? (
              <Img
                className={css(styles.image)}
                fixed={cell.film.thumbnail.fixed}
                alt={cell.film.title}
              />
            ) : (
              ''
            )}
          </a>
        </div>
      );
    }

    return (
      <div className={css(styles.wrapper, isMobile && styles.mobile)} style={style}>
        {content}
      </div>
    );
  }
}

Cell.propTypes = {
  style: PropTypes.object.isRequired,
  cell: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  special: PropTypes.bool,
  dragging: PropTypes.bool.isRequired,
  onclick: PropTypes.func,
};

export default Cell;
