import React from 'react';
import * as PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { css } from 'utilities';

import styles from './footer.module.scss';

class Footer extends React.PureComponent {
  render() {
    const { ready } = this.props;
    return (
      <div className={css(styles.wrapper, ready && styles.ready)}>
        <div className={styles.section}>
          <span className={styles.copyright}>{`© 2019 - ${new Date().getFullYear()} Matan Hatsav`}</span>
        </div>
        <div className={styles.section}>
          <Link className={styles.link} to="/privacy">
            Privacy
          </Link>
        </div>
      </div>
    );
  }
}

Footer.propTypes = {
  ready: PropTypes.bool.isRequired,
};

export default Footer;
