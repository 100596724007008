import React from 'react';
import { graphql } from 'gatsby';
import * as PropTypes from 'prop-types';
import Head from 'components/Head';
import Footer from 'components/Footer';
import Grid from 'components/Grid';
import { css } from 'utilities';

import styles from 'styles/pages/home.module.scss';
import FilmTakover from 'components/FilmTakeover';

class PageIndex extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pageReady: false,
    };
  }

  componentDidMount() {
    const { pageReady } = this.state;
    if (!pageReady) {
      setTimeout(() => {
        this.setState({ pageReady: true });
      }, 0);
    }
  }

  render() {
    const { data } = this.props;
    const { pageReady } = this.state;
    const { title, films } = data.contentfulPageHome;

    return (
      <>
        <Head />
        <Grid ready={pageReady} films={films} title={title} />
        <Footer ready={pageReady} />
        <FilmTakover open={false} />
      </>
    );
  }
}

PageIndex.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PageIndex;

export const pageQuery = graphql`
  query {
    contentfulPageHome {
      title
      films {
        title
        description {
          description
        }
        vimeoId
        thumbnail {
          fixed(width: 320) {
            srcSet
            src
            aspectRatio
            width
            height
          }
        }
      }
    }
  }
`;
