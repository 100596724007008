import React from 'react';

export const isString = value => {
  return Object.prototype.toString.call(value) === '[object String]';
};

export const css = (...args) => {
  let stylesList = [];

  args
    .filter(style => !!style)
    .forEach(style => {
      if (Array.isArray(style)) {
        stylesList = stylesList.concat(css(...style));
      } else if (isString(style)) {
        stylesList.push(style);
      }
    });

  return stylesList.join(' ');
};

export const newLineToBreak = string => {
  const randString = `break-${Math.random() * 100}`;
  const splitString = string.split('\n\n');
  return splitString.map((s, index) => {
    if (index === 0) {
      return <span key={`${randString}-${index}}`}>{s}</span>;
    }
    return (
      <span key={`${randString}-${index}}`}>
        <br />
        {s}
      </span>
    );
  });
};

export const getInteractionPosition = e => {
  const interaction = {};
  if (e.clientX && e.clientY) {
    interaction.x = e.clientX;
    interaction.y = e.clientY;
  } else {
    interaction.x = e.changedTouches[0].clientX;
    interaction.y = e.changedTouches[0].clientY;
  }
  return interaction;
};
