import React from 'react';
import ReactDOM from 'react-dom';
import * as PropTypes from 'prop-types';
import Player from '@vimeo/player';
import marked from 'marked';
import { css } from 'utilities';

import styles from './filmTakeover.module.scss';

class FilmTakover extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      playerReady: false,
    };
  }

  componentDidMount() {
    this.setupThePlayer();
  }

  componentDidUpdate() {
    this.setupThePlayer();
  }

  setupThePlayer() {
    const { film } = this.props;
    this.closeButtonRef.addEventListener('click', this.handleClose);
    if (film.vimeoId) {
      this.player = new Player(this.iframe);
      if (this.player) {
        this.player.setVolume(1);
      }
    }
    this.setState({ playerReady: true });
  }

  handleClose = e => {
    const { closeTakeover } = this.props;
    if (this.player) {
      this.player.pause();
    }
    closeTakeover();
    this.setState({ playerReady: false });
  };

  render() {
    const { film, open } = this.props;
    const { playerReady } = this.state;
    const descriptionFormatted = film.description ? marked(film.description.description) : '';
    return (
      <div className={css(styles.wrapper, open && styles.open)} key={film.title}>
        <div className={styles.content}>
          <div className={styles.videoContent}>
            <button
              ref={r => {
                this.closeButtonRef = r;
              }}
              className={css(styles.closeButton)}
              type="button"
            />
            <div className={css(styles.videoWrapper, playerReady && styles.videoReady)}>
              <div className={styles.videoSpacer} />
              {film.vimeoId ? (
                <iframe
                  className={css(styles.video)}
                  title={film.title}
                  ref={r => {
                    this.iframe = r;
                  }}
                  src={`https://player.vimeo.com/video/${film.vimeoId}?byline=false&title=false&portrait=false&color=ffffff&controls=true&autoplay=0&transparent=false&autopause=false&playsinline=false&loop=false`}
                  width="1600"
                  height="900"
                  frameBorder="0"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  allowFullScreen={true}
                  allow="autoplay; encrypted-media"
                  data-ready="true"
                  style={{}}
                />
              ) : (
                ''
              )}
            </div>
          </div>
          <div className={styles.copyContent}>
            <h2 className={styles.title}>{film.title}</h2>
            <div
              className={styles.copy}
              dangerouslySetInnerHTML={{ __html: descriptionFormatted }}
            />
          </div>
        </div>
      </div>
    );
  }
}

FilmTakover.propTypes = {
  film: PropTypes.object,
  open: PropTypes.bool.isRequired,
  closeTakeover: PropTypes.func,
};

FilmTakover.defaultProps = {
  film: { title: '' },
};

export default FilmTakover;
